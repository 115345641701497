/*eslint-disable eqeqeq*/
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Atendimentos } from "../../components/Atendimentos";
import { MenuInfoAtendimentos } from "../../components/Atendimentos/MenuInfoAtendimentos";
import { ModalAtendimentoSelecionado } from "../../components/ModalAtendimentoSelecionado";
import api from "../../services/api";
import { supabase } from "../../services/supabase";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [atendimentosPorStatus, setAtendimentosPorStatus] = useState([]);
  const [data, setData] = useState(new Date().toISOString().split("T")[0]);
  const [isPresente, setIsPresente] = useState("all"); // 'all', 'true', 'false'
  const [snRespondido, setSnRespondido] = useState("all"); // 'all', 'true', 'false'
  const [snLaudado, setSnLaudado] = useState("all");
  const toast = useToast();
  const [refresh, setRefresh] = useState(false);
  const history = useHistory(); // Substitui useNavigate
  const [atendimentosFull, setAtendimentosFull] = useState([]);
  const [isOpenModalAtendimento, setIsOpenModalAtendimento] = useState(false);
  const [atendimentoId, setAtendimentoId] = useState(null);

  // Função para buscar atendimentos
  const fetchAtendimentos = () => {
    setIsLoading(true);

    api
      .get(`atendimentos/date/${data}`)
      .then((res) => {
        setAtendimentosFull(res.data);
      })
      .catch(() => {});

    // Define o parâmetro de presença
    const params = {
      isPresente: isPresente !== "all" ? isPresente : null,
      snRespondido: snRespondido !== "all" ? snRespondido : null,
      snLaudado: snLaudado !== "all" ? snLaudado : null,
    };

    api
      .get(`atendimentos/date/${data}`, { params })
      .then((res) => {
        setAtendimentosPorStatus(res.data);
      })
      .catch(() => {
        toast({
          title: "Erro ao buscar atendimentos",
          description: "Verifique os filtros e tente novamente.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Middleware para verificar token
  const checkAuth = () => {
    api.get("/me").catch(() => {
      toast({
        title: "Acesso negado",
        description: "Você precisa estar logado para acessar esta página.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      history.push("/"); // Redireciona para login
    });
  };

  // Verifica autenticação no carregamento
  useEffect(() => checkAuth(), []);

  // Atualiza os dados sempre que a data ou o filtro de presença mudar
  useEffect(() => {
    fetchAtendimentos();
  }, [data, isPresente, snRespondido, refresh, snLaudado]);

  // Forçar atualização sempre que houver uma modificação nos atendimentos
  useEffect(() => {
    const channel = supabase
      .channel("attendance")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "atendimentos_logs",
        },
        async () => {
          setIsLoading(true);

          api
            .get(`atendimentos/date/${data}`)
            .then((res) => {
              setAtendimentosFull(res.data);
            })
            .catch(() => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(channel);
    };
  }, [supabase, data]);

  // Função para abrir o modal de atendimento selecionado
  const handleSelectedAtendimento = (atendimentoId) => {
    setAtendimentoId(atendimentoId);
    fetchAtendimentos();
    setIsOpenModalAtendimento(true);
  };

  return (
    <>
      <ModalAtendimentoSelecionado
        isOpen={isOpenModalAtendimento}
        onClose={() => setIsOpenModalAtendimento(false)}
        atendimentoId={atendimentoId}
      />

      <VStack w="full" alignItems={"flex-start"} spacing={3}>
        <HStack justifyContent={"initial"} align={"start"} w="full">
          <VStack
            bg="white"
            p={8}
            borderRadius="md"
            boxShadow="base"
            w="300px"
            spacing={4}
            alignItems={"flex-start"}
          >
            <Input
              value={data}
              type="date"
              onChange={(e) => setData(e.target.value)}
              bg={"white"}
              w={"full"}
              borderRadius={8}
              id="input-date"
            />
            <MenuInfoAtendimentos
              info={atendimentosFull}
              isLoading={isLoading}
              onClick={handleSelectedAtendimento}
            />
          </VStack>

          <VStack
            textAlign={"left"}
            justifyContent={"left"}
            alignItems={"left"}
            p={4}
            bg="white"
            borderRadius={8}
            boxShadow={"base"}
            w="full"
            minH={"100vh"}
          >
            {/* Filtros */}
            <HStack
              spacing={4}
              justifyContent="flex-end"
              alignItems="center"
              borderRadius="md"
            >
              {/* Dropdown das respostas */}
              <FormControl maxW="200px">
                <FormLabel>Respondidos</FormLabel>
                <Select
                  leftIcon={<FiFilter />}
                  colorScheme="gray"
                  value={snRespondido}
                  onChange={(e) => setSnRespondido(e.target.value)}
                  bg="white"
                  size="md"
                  borderRadius={8}
                >
                  <option value="all">Todos</option>
                  <option value="S">Sim</option>
                  <option value="N">Não</option>
                </Select>
              </FormControl>

              {/* Dropdown para selecionar presença */}
              <FormControl maxW="200px">
                <FormLabel>Presença</FormLabel>
                <Select
                  value={isPresente}
                  onChange={(e) => setIsPresente(e.target.value)}
                  bg="white"
                  size="md"
                  borderRadius={8}
                >
                  <option value="all">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </Select>
              </FormControl>

              {/* Dropdown para laudos  */}
              <FormControl maxW="200px">
                <FormLabel>Laudados</FormLabel>
                <Select
                  value={snLaudado}
                  onChange={(e) => setSnLaudado(e.target.value)}
                  bg="white"
                  size="md"
                >
                  <option value="all">Todos</option>
                  <option value="S">Sim</option>
                  <option value="N">Não</option>
                </Select>
              </FormControl>
            </HStack>

            {/* Lista dos atendimentos */}
            <Atendimentos
              info={atendimentosPorStatus}
              onClick={handleSelectedAtendimento}
            />
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}
