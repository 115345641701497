import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

interface Action {
  title: string;
  handleSetVal: () => void;
}

function Opcoes({ title, handleSetVal }: Action) {
  return (
    <FormControl id="selects">
      <FormLabel>{title}</FormLabel>
      <Select
        bg="white"
        className="input-secundario"
        onChange={(e) => handleSetVal(e.target.value)}
      >
        <option value="DEFAULT" selected disabled>
          Selecione uma opção
        </option>
        <option value="Nunca">N - Nunca</option>
        <option value="Raro">R - Raro</option>
        <option value="As Vezes">A - As Vezes</option>
        <option value="Frequente">F - Frequente</option>
        <option value="Sempre">S -Sempre</option>
      </Select>
    </FormControl>
  );
}

export default Opcoes;
