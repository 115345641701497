import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import { default as React } from "react";
import { FaBed } from "react-icons/fa";
import {
  FiCheck,
  FiFilter,
  FiRepeat,
  FiSearch,
  FiXCircle,
} from "react-icons/fi";

const InfoRow = ({ icon, label, value }) => (
  <HStack>
    <Icon as={icon} />
    <Text>
      {label ? `${label}:` : ""} {value || "N/A"}
    </Text>
  </HStack>
);

export function Atendimentos({ info, onClick }) {
  return (
    <>
      <HStack>
        <Icon as={FiFilter} />
        <Text>{info.length} atendimentos</Text>
      </HStack>
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        {/* Grid de todos so atendimentos */}
        {info.map((atendimento) => (
          <Box
            key={atendimento.id}
            onClick={() => onClick(atendimento.id)}
            p={4}
            cursor={"pointer"}
            borderRadius={8}
            boxShadow={"base"}
            bg="white"
          >
            <Image
              src="images/poli.webp"
              alt="Green double couch with wooden legs"
              borderRadius="lg"
              mb={4}
            />

            <Heading size="xs" fontSize={"xs"}>
              {atendimento.pacientes?.nm_paciente.length > 18
                ? `${atendimento.pacientes.nm_paciente.slice(0, 18)}...`
                : atendimento.pacientes?.nm_paciente}
            </Heading>

            <InfoRow icon={FaBed} label="" value={atendimento?.ds_leito} />

            <SimpleGrid columns={[1, 1, 2]} spacing={1} marginTop={4}>
              {atendimento.isRequerRefazer && (
                <Tag size="sm" variant="subtle" colorScheme="red">
                  <TagLeftIcon boxSize="12px" as={FiRepeat} />
                  <TagLabel>Refazer Exame</TagLabel>
                </Tag>
              )}
              <Tag
                size="sm"
                variant="subtle"
                colorScheme={atendimento.sn_laudado == "S" ? "green" : "blue"}
              >
                <TagLeftIcon
                  boxSize="12px"
                  as={atendimento.sn_laudado == "S" ? FiSearch : FiXCircle}
                />
                <TagLabel>
                  {atendimento.sn_laudado == "S" ? "Laudado" : "Sem Laudo"}
                </TagLabel>
              </Tag>

              <Tag
                size="sm"
                variant="subtle"
                colorScheme={atendimento.sn_respondido == "S" ? "green" : "red"}
              >
                <TagLeftIcon
                  boxSize="12px"
                  as={atendimento.sn_respondido == "S" ? FiCheck : FiXCircle}
                />
                <TagLabel>
                  {atendimento.sn_respondido == "S"
                    ? "Respondido"
                    : "Sem resposta"}
                </TagLabel>
              </Tag>
            </SimpleGrid>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
}
