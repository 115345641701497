import { Center, Heading, Icon, VStack } from "@chakra-ui/react";
import React from "react";
import { FaCheck } from "react-icons/fa";

export default function ConfirmacaoSend() {
  localStorage.clear();
  return (
    <Center h={"100vh"}>
      <VStack>
        <Heading as="h3" color={"gray.800"}>
          Enviado com sucesso, obrigado.
        </Heading>
        <Icon as={FaCheck} color={"green.500"} w={10} h={10} />
      </VStack>
    </Center>
  );
}
