import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

interface Action {
  title: string;
  handleSetVal: () => void;
}

function AnamneseSIMeNAO({ title, handleSetVal }: Action) {
  return (
    <FormControl id="selects" isRequired>
      <FormLabel>{title}</FormLabel>
      <Select
        className="input-secundario"
        onChange={(e) => handleSetVal(e.target.value)}
        bg="white"
      >
        <option selected disabled>
          Selecione uma opção
        </option>
        <option value="Sim">Sim</option>
        <option value="Nao">Não</option>
      </Select>
    </FormControl>
  );
}

export default AnamneseSIMeNAO;
