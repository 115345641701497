import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FaBed, FaHospital } from "react-icons/fa";
import {
  FiCalendar,
  FiCheck,
  FiEdit,
  FiExternalLink,
  FiEye,
  FiKey,
  FiPenTool,
  FiRepeat,
  FiSave,
  FiSearch,
  FiStar,
  FiUser,
  FiXCircle,
} from "react-icons/fi";
import api from "../services/api";
import { AnamneseRespostas } from "./AnamneseRespostas";

const InfoRow = ({ icon, label, value }) => (
  <HStack>
    <Icon as={icon} />
    <Text>
      {label ? `${label}:` : ""} {value || "N/A"}
    </Text>
  </HStack>
);

export function ModalAtendimentoSelecionado({
  isOpen,
  onClose,
  atendimentoId,
}) {
  const [atendimentoSelected, setAtendimentoSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [leitos, setLeitos] = useState([]);
  const [tiposResultados, setTiposResultados] = useState([]);
  const debounceTimeout = useRef(null); // Armazena o timeout do debounce
  const [modalIsOpenLaudar, setModalIsOpenLaudar] = useState(false);
  const [id_resultado, setIdResultado] = useState("");
  const [File, setFile] = useState([]);
  const [leitoSelecionado, setLeitoSelecionado] = useState("");
  const [isOpenModalLeito, setisOpenModalLeito] = useState(false);

  // Função para atualizar o atendimento
  const updateAtendimento = (id, data) => {
    setIsLoading(true);
    api
      .put("atendimentos/" + id, data)
      .then((res) => {
        setAtendimentoSelected(res.data);
        toast({
          title: "Atendimento atualizado",
          description: "Atendimento atualizado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar atendimento",
          description: "Erro ao atualizar atendimento",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Abrir o modal para confirmar a alteração do leito
  const handleOpenModalLeito = (leito) => {
    setLeitoSelecionado(leito);
    setisOpenModalLeito(true);
  };

  // Função para lidar com mudanças no campo de observação
  const handleObservacaoChange = (value) => {
    // Limpa o timeout anterior
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Define um novo timeout para o debounce
    debounceTimeout.current = setTimeout(() => {
      // Chama a função de atualização do backend após a pausa na digitação
      updateAtendimento(atendimentoSelected.id, { ds_observacao: value });
    }, 500); // 1000ms = 1 segundo
  };

  // Função para anexar o laudo do paciente
  async function handleAnexarLaudo(e) {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", File);
    formData.append("id_atendimento", atendimentoSelected.id);
    formData.append("id_cliente", atendimentoSelected.id_cliente);
    formData.append("id_resultado", id_resultado);

    api
      .post("laudos/laudar", formData, {})
      .then(() => {
        setModalIsOpenLaudar(false);
        toast({
          title: "Laudo anexado",
          description: "Laudo anexado com sucesso",
          status: "success",
          duration: 3000,
          position: "top",
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao anexar laudo",
          description: "Erro ao anexar laudo",
          status: "error",
          duration: 3000,
          position: "top",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // Consultar os documentos anexados
  const handleDocumentoAnexado = (data) => {
    setIsLoading(true);

    api
      .post("pedidos/documentos", {
        attendance: data.cd_atendimento,
        id_cliente: data.id_cliente,
      })
      .then((response) => {
        response.data.map((item) => {
          window.open(item.url, "_blank");
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao buscar documentos",
          description: "Erro ao buscar documentos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Criar um link para o paciente preencher
  async function handleLinkAnamnese(atendimentoLink) {
    const token = await axios
      .post(
        "https://polissonografia-backend-production.up.railway.app/login/pacientes",
        { key: "XnJNIdcD6ocAvezS4b" }
      )
      .then((res) => {
        return res.data.token;
      })
      .catch(() => {
        toast({
          title: "Erro ao gerar link de anamnese",
          description: "Erro ao buscar token",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      });

    const link = `https://polissonografia.com/anamnese/${atendimentoLink.id}/${atendimentoLink.id_cliente}/${token}`;
    window.open(link, "_blank");
  }

  // Confirmar a alteração do leito
  const handleConfirmarLeito = () => {
    updateAtendimento(atendimentoSelected.id, { ds_leito: leitoSelecionado });
    setisOpenModalLeito(false);
  };

  // Refazer o exame
  const handleRefazerExame = (atendimento) => {
    setIsLoading(true);
    updateAtendimento(atendimento.id, {
      isRequerRefazer: !atendimento.isRequerRefazer,
    });
  };

  // Busca o atendimento selecionado
  useEffect(() => {
    if (!isOpen) return;
    setAtendimentoSelected([]);

    setIsLoading(true);

    api
      .get("atendimentos/find/" + atendimentoId)
      .then((res) => {
        setAtendimentoSelected(res.data);
      })
      .catch(() => {
        toast({
          title: "Erro ao buscar atendimentos",
          description: "Erro ao buscar atendimentos",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isOpen]);

  // Consultar todos os leitos disponíveis
  useEffect(() => {
    api
      .get("leitos")
      .then((res) => {
        setLeitos(res.data);
      })
      .catch(() => {
        toast({
          title: "Erro ao buscar leitos",
          description: "Erro ao buscar leitos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    api
      .get("resultados")
      .then((res) => {
        setTiposResultados(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack alignItems={"left"} textAlign={"left"}>
              <Text>{atendimentoSelected.pacientes?.nm_paciente} </Text>
              <HStack>
                {atendimentoSelected.isPrioridade && (
                  <Tag colorScheme="red">
                    <TagLeftIcon boxSize="12px" as={FiStar} />
                    <TagLabel>Prioridade</TagLabel>
                  </Tag>
                )}

                {/* Tag que o paciente está refazendo */}
                {atendimentoSelected.sn_refazendo === "S" && (
                  <Tag colorScheme="red">
                    <TagLeftIcon boxSize="12px" as={FiRepeat} />
                    <TagLabel>Refazendo</TagLabel>
                  </Tag>
                )}

                {atendimentoSelected.isRequerRefazer && (
                  <Tag colorScheme="red">
                    <TagLeftIcon boxSize="12px" as={FiRepeat} />
                    <TagLabel>Refazer Exame</TagLabel>
                  </Tag>
                )}
              </HStack>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Apenas quando estiver carregando  */}
            <Progress
              size="xs"
              isIndeterminate={isLoading}
              bg="transparent"
              h={"2"}
            />

            <VStack justifyContent={"left"} alignItems={"left"}>
              <InfoRow
                icon={FiUser}
                label="Prontuário"
                value={atendimentoSelected.pacientes?.cd_paciente}
              />

              <InfoRow
                icon={FiCalendar}
                label="Data"
                value={atendimentoSelected.pacientes?.dt_nascimento}
              />

              <InfoRow
                icon={FiKey}
                label="Atendimento"
                value={atendimentoSelected?.cd_atendimento}
              />

              <InfoRow
                icon={FiKey}
                label="Pedido"
                value={atendimentoSelected?.cd_pedido}
              />

              <InfoRow
                icon={FiSearch}
                label="Exame"
                value={atendimentoSelected?.nm_exame}
              />

              <InfoRow
                icon={FiUser}
                label="Solicitante"
                value={atendimentoSelected?.nm_medico_solicitante}
              />

              <Flex justifyContent="space-between" w="full">
                <HStack mr={4}>
                  <InfoRow
                    icon={FaBed}
                    label="Leito"
                    value={atendimentoSelected?.ds_leito}
                  />
                </HStack>
                <FormControl maxW={"85%"}>
                  <Select
                    variant="Filled"
                    size="sm"
                    placeholder="Selecione para alterar o leito."
                    onChange={(e) => handleOpenModalLeito(e.target.value)}
                  >
                    {leitos.map((leito) => (
                      <option key={leito.id} value={leito.ds_leito}>
                        {leito.ds_leito}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <HStack>
                <Icon as={FiEdit} />
                <Textarea
                  defaultValue={atendimentoSelected?.ds_observacao || ""}
                  onChange={(e) => handleObservacaoChange(e.target.value)}
                />
              </HStack>

              {/* Ações do usuário  */}
              <Text fontWeight={"semibold"} pt={4}>
                Ações:
              </Text>
              <Divider />

              <SimpleGrid columns={[1, 1, 5]} spacing={4} marginTop={4}>
                <Button
                  colorScheme={
                    atendimentoSelected.sn_laudado === "S" ? "gray" : "green"
                  }
                  leftIcon={<FiSave />}
                  // Retificar laudo -> Sol. pela andréia 09/12/2024
                  onClick={() => setModalIsOpenLaudar(true)}
                >
                  {atendimentoSelected.sn_laudado === "S"
                    ? "Laudado"
                    : "Laudar"}
                </Button>
                {/* Visualizar o laudo do paciente */}
                {atendimentoSelected.sn_laudado === "S" && (
                  <Button
                    colorScheme={"green"}
                    leftIcon={<FiEye />}
                    onClick={() => {
                      window.open(atendimentoSelected.url_aws_laudo, "_blank");
                    }}
                  >
                    Visualizar o Laudo
                  </Button>
                )}
                {/* Confirmar a presença do paciente */}
                <Button
                  colorScheme={
                    atendimentoSelected.isPresente ? "gray" : "green"
                  }
                  leftIcon={<FaHospital />}
                  isDisabled={atendimentoSelected.isPresente}
                  onClick={() =>
                    updateAtendimento(atendimentoSelected.id, {
                      isPresente: true,
                      hr_presenca: new Date(),
                    })
                  }
                >
                  {atendimentoSelected.isPresente
                    ? "Presença Confirmada"
                    : "Confirmar presença"}
                </Button>
                <Button
                  leftIcon={<FiExternalLink />}
                  onClick={() => handleDocumentoAnexado(atendimentoSelected)}
                >
                  Pedido Médico
                </Button>
                <Button
                  onClick={() => handleLinkAnamnese(atendimentoSelected)}
                  leftIcon={<FiPenTool size="16" />}
                  colorScheme="gray"
                >
                  Link Anamnese
                </Button>
                {/* // Botão para refazer o exame */}
                <Button
                  onClick={() => handleRefazerExame(atendimentoSelected)}
                  leftIcon={<FiRepeat size="16" />}
                  colorScheme="gray"
                >
                  Refazer
                </Button>
                {/* Marcar como prioridade */}
                {!atendimentoSelected.isPrioridade && (
                  <Button
                    colorScheme={"gray"}
                    leftIcon={<FiStar />}
                    onClick={() => {
                      updateAtendimento(atendimentoSelected.id, {
                        isPrioridade: !atendimentoSelected.isPrioridade,
                      });
                    }}
                  >
                    Prioridade
                  </Button>
                )}
                {/* Botão para marcar como atendido  */}
                <Button
                  colorScheme={
                    atendimentoSelected.sn_atendido === "S" ? "gray" : "green"
                  }
                  leftIcon={<FiCheck />}
                  isDisabled={atendimentoSelected.sn_atendido === "S"}
                  onClick={() => {
                    updateAtendimento(atendimentoSelected.id, {
                      sn_atendido:
                        atendimentoSelected.sn_atendido === "S" ? "N" : "S",
                    });
                  }}
                >
                  {atendimentoSelected.sn_atendido === "S"
                    ? "Atendido"
                    : "Marcar como atendido"}
                </Button>
              </SimpleGrid>
            </VStack>
            <AnamneseRespostas Atendimento={atendimentoSelected} />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal para alterar o leito  */}
      <Modal
        isOpen={isOpenModalLeito}
        size="sm"
        onClose={() => setisOpenModalLeito(false)}
      >
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.7)" // Fundo semitransparente
          backdropFilter="blur(4px)" // Adiciona um desfoque ao fundo
        />
        <ModalContent>
          <ModalHeader>Alterar Leito</ModalHeader>
          <ModalBody>
            <VStack spacing={8}>
              <Text fontSize={"md"}>
                Tem certeza que deseja alterar o leito para {leitoSelecionado}?
              </Text>
              <HStack>
                <Button
                  colorScheme="green"
                  leftIcon={<FiCheck />}
                  onClick={() => handleConfirmarLeito()}
                >
                  Sim
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => setisOpenModalLeito(false)}
                  leftIcon={<FiXCircle />}
                >
                  Não
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal para laudar os atendimetos */}
      <Modal
        isOpen={modalIsOpenLaudar}
        size="sm"
        onClose={() => setModalIsOpenLaudar(false)}
      >
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.7)" // Fundo semitransparente
          backdropFilter="blur(4px)" // Adiciona um desfoque ao fundo
        />
        <ModalContent>
          <ModalHeader>
            <InfoRow
              icon={FiSearch}
              label=""
              value={atendimentoSelected.pacientes?.nm_paciente}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              as="form"
              spacing={4}
              onSubmit={handleAnexarLaudo}
              encType="multipart/form-data"
              id="form-laudar"
            >
              <FormControl isRequired>
                <FormLabel>Resultado</FormLabel>
                <Select
                  placeholder="Selecione o resultado"
                  onChange={(e) => setIdResultado(e.target.value)}
                >
                  {tiposResultados.map((resultado, index) => (
                    <option key={index} value={resultado.id}>
                      {resultado.desc_tipo_resultado}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Laudo</FormLabel>
                <Input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </FormControl>

              <Button
                leftIcon={<FiSave />}
                type="submit"
                colorScheme="green"
                size="lg"
                isLoading={isLoading}
              >
                Salvar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

ModalAtendimentoSelecionado.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paciente: PropTypes.shape({
    nome: PropTypes.string,
    horario: PropTypes.string,
  }),
};
