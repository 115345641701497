import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import api from "../../services/api";

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  async function handleLogin(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await api.post("usuarios/login", { usuario, senha });
      if (res.data.user) {
        localStorage.setItem("token", "Bearer " + res.data.token);
        localStorage.setItem("id_acesso", res.data.user[0].id_acesso);
        localStorage.setItem("id_usuario", res.data.user[0].id);
        localStorage.setItem("id_cliente", res.data.user[0].id_cliente);
        localStorage.setItem("tp_acesso", res.data.user[0].tp_acesso);
        localStorage.setItem("usuario", res.data.user[0].usuario);
        window.location.href = "/dashboard";
      }
    } catch {
      toast({
        title: "Erro de autenticação",
        description: "Usuário ou senha incorretos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container
      maxW="sm"
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="xl"
        p={8}
        w="100%"
        as="form"
        onSubmit={handleLogin}
      >
        <VStack spacing={4} align="stretch">
          <Heading size="lg" textAlign="center" color="gray.700">
            Bem-vindo
          </Heading>
          <Text textAlign="center" color="gray.500">
            Por favor, entre com suas credenciais
          </Text>
          <FormControl isRequired>
            <FormLabel>Usuário</FormLabel>
            <Input
              type="text"
              placeholder="Digite seu usuário"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              focusBorderColor="blue.400"
              variant="outline"
              bg="gray.50"
              id="usuario"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Senha</FormLabel>
            <Input
              type="password"
              placeholder="Digite sua senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              focusBorderColor="blue.400"
              variant="outline"
              bg="gray.50"
              id="senha"
            />
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            isFullWidth
            isLoading={isLoading}
            loadingText="Entrando"
            id="btn-entrar"
          >
            Entrar
          </Button>
        </VStack>
      </Box>
    </Container>
  );
}
