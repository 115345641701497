import {
  Box,
  List,
  ListIcon,
  ListItem,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { FaHospital } from "react-icons/fa";
import { FiCheck, FiRepeat, FiStar, FiXCircle } from "react-icons/fi";

export function MenuInfoAtendimentos({ info, isLoading, onClick }) {
  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Sao_Paulo",
    }).format(date);
  };

  const truncateName = (name, maxLength) =>
    name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;

  const prioridades = info.filter((paciente) => paciente.isPrioridade);
  const aguardandoResposta = info.filter(
    (paciente) => paciente.sn_respondido === "N"
  );
  const presentesOrdenados = info
    .filter((paciente) => paciente.isPresente)
    .sort((a, b) => new Date(a.hr_presenca) - new Date(b.hr_presenca));

  // Pacientes que precisam refazer o exame
  const refazerExame = info.filter((paciente) => paciente.isRequerRefazer);

  return (
    <VStack
      minH="100vh"
      flexDir="column"
      spacing={4}
      alignItems="flex-start"
      data-testid="menu-info-atendimentos"
    >
      <Progress
        size="xs"
        isIndeterminate={isLoading}
        w="full"
        bg="transparent"
      />

      {/* Pacientes que precisam refazer o exame */}
      {refazerExame.length > 0 && (
        <DataBox
          title={`Refazer (${refazerExame.length})`}
          data={refazerExame}
          emptyMessage="Nenhum paciente para refazer."
          listId="list-prioridades"
          icon={FiRepeat}
          iconColor="red.500"
          formatName={(paciente) =>
            truncateName(paciente.pacientes.nm_paciente, 16)
          }
          onClick={onClick}
        />
      )}

      <DataBox
        title={`Prioridades (${prioridades.length})`}
        data={prioridades}
        emptyMessage="Nenhum paciente prioridade."
        listId="list-prioridades"
        icon={FiStar}
        iconColor="red.500"
        formatName={(paciente) =>
          truncateName(paciente.pacientes.nm_paciente, 16)
        }
        onClick={onClick}
      />

      <DataBox
        title={`Pacientes Presentes (${presentesOrdenados.length} / ${info.length})`}
        data={presentesOrdenados}
        listId="list-atendidos"
        renderItem={(paciente) => (
          <>
            <ListIcon
              as={paciente.sn_atendido === "S" ? FiCheck : FaHospital}
              color={paciente.sn_atendido === "S" ? "green.500" : "gray.500"}
            />
            <Text as="span" fontWeight="bold">
              {formatTime(paciente.hr_presenca)}
            </Text>{" "}
            {truncateName(paciente.pacientes.nm_paciente, 16)}
          </>
        )}
        onClick={onClick}
      />

      <DataBox
        title={`Questionários não respondidos (${aguardandoResposta.length})`}
        data={aguardandoResposta}
        listId="list-aguardando-resposta"
        icon={FiXCircle}
        iconColor="red.500"
        formatName={(paciente) =>
          truncateName(paciente.pacientes.nm_paciente, 20)
        }
        onClick={onClick}
      />
    </VStack>
  );
}

function DataBox({
  title,
  data,
  emptyMessage,
  listId,
  icon,
  iconColor,
  formatName,
  renderItem,
  onClick,
}) {
  return (
    <Box data-testid={listId}>
      <Text fontWeight="bold" fontSize="xs">
        {title}
      </Text>
      {data.length === 0 && emptyMessage && (
        <Text fontSize="xs">{emptyMessage}</Text>
      )}
      <List spacing={2} id={listId}>
        {data.map((item, index) => (
          <ListItem
            key={index}
            fontSize="xs"
            cursor={"pointer"}
            _hover={{ bg: "gray.100" }}
            onClick={() => onClick(item.id)}
            data-testid={`${listId}-item-${index}`}
          >
            {renderItem ? (
              renderItem(item)
            ) : (
              <>
                <ListIcon as={icon} color={iconColor} />
                {formatName(item)}
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

DataBox.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string,
  listId: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  iconColor: PropTypes.string,
  formatName: PropTypes.func,
  renderItem: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

MenuInfoAtendimentos.propTypes = {
  info: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
