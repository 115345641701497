import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./global.css";
import Routes from "./routes";
import { themePerson } from "./styles/theme";

const mytheme = extendTheme(themePerson);

function App() {
  return (
    <ChakraProvider resetCSS theme={mytheme}>
      <Fragment>
        <ToastContainer />
        <Routes />
      </Fragment>
    </ChakraProvider>
  );
}

export default App;
