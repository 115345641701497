import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

interface Action {
  title: string;
  orientacoes: string;
  handleSetVal: () => void;
}

function InputAnamnese({ title, orientacoes, handleSetVal }: Action) {
  return (
    <FormControl id="selects">
      <FormLabel>{title}</FormLabel>
      <Input
        p={4}
        bg="white"
        type="text"
        placeholder={orientacoes}
        onChange={(e) => handleSetVal(e.target.value)}
      />
    </FormControl>
  );
}

export default InputAnamnese;
