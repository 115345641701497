import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

interface Action {
  title: string;
  handleSetVal: () => void;
}

function AnamneseOpcoesEscala({ title, handleSetVal }: Action) {
  return (
    <FormControl id="selects">
      <FormLabel>{title}</FormLabel>
      <Select
        className="input-secundario"
        onChange={(e) => handleSetVal(e.target.value)}
        bg={"white"}
      >
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </Select>
    </FormControl>
  );
}

export default AnamneseOpcoesEscala;
