import { Divider, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Question = ({ label, answer }) => (
  <HStack>
    <Text fontWeight="semibold" fontSize={"lg"}>
      {label}
    </Text>
    <Text fontSize={"lg"}>{answer || "Não informado"}</Text>
  </HStack>
);

const Section = ({ title, questions }) => (
  <VStack align="start" spacing={4} w="full" mt={6} borderRadius={8}>
    <Heading as="h4" size="md">
      {title}
    </Heading>
    {questions.map((q, index) => (
      <Question key={index} label={q.label} answer={q.answer} />
    ))}
  </VStack>
);
export function AnamneseRespostas({ Atendimento }) {
  if (!Atendimento) return <Text>Nenhuma informação disponível</Text>;

  return (
    <VStack
      align="start"
      spacing={6}
      w="full"
      p={4}
      bg="gray.50"
      borderRadius="md"
    >
      <Divider />
      <Heading as="h2" size="lg">
        Anamnese
      </Heading>

      {/* Informações Gerais */}
      <Section
        title="Informações Gerais"
        questions={[
          { label: "Peso", answer: `${Atendimento.vl_peso} KG` },
          { label: "Altura", answer: Atendimento.vl_altura },
          {
            label: "Solicitante",
            answer: `Dr(a) ${Atendimento.nm_medico_solicitante}`,
          },
        ]}
      />

      {/* Sobre o Ronco */}
      <Section
        title="Sobre o Ronco"
        questions={[
          { label: "1 - Você Ronca?", answer: Atendimento.q1_ronco1 },
          { label: "2 - Seu ronco é alto?", answer: Atendimento.q1_ronco2 },
          {
            label: "3 - A posição de dormir influência no ronco?",
            answer: Atendimento.q1_ronco3,
          },
          {
            label: "4 - Você tem obstrução nasal?",
            answer: Atendimento.q1_ronco4,
          },
          { label: "5 - Você tem coriza?", answer: Atendimento.q1_ronco5 },
        ]}
      />

      {/* Sobre Apnéia */}
      <Section
        title="Sobre Apnéia"
        questions={[
          {
            label:
              "1 - Alguém já lhe disse que você para de respirar durante a noite?",
            answer: Atendimento.q2_apneia1,
          },
          {
            label: "2 - Você já acordou engasgado?",
            answer: Atendimento.q2_apneia2,
          },
          {
            label: "3 - Você já acordou com falta de ar?",
            answer: Atendimento.q2_apneia3,
          },
        ]}
      />

      {/* Sobre Sonolência */}
      <Section
        title="Sobre Sonolência"
        questions={[
          {
            label: "1 - Você já dormiu no cinema?",
            answer: Atendimento.q3_sonolencia1,
          },
          {
            label: "2 - Você cochila lendo jornal?",
            answer: Atendimento.q3_sonolencia2,
          },
          {
            label: "3 - Você fica extremamente sonolento no trânsito?",
            answer: Atendimento.q3_sonolencia3,
          },
          {
            label: "4 - Você já dormiu dirigindo?",
            answer: Atendimento.q3_sonolencia4,
          },
          {
            label: "5 - Você já dormiu em reunião importante?",
            answer: Atendimento.q3_sonolencia5,
          },
        ]}
      />

      {/* Sobre o Sono */}
      <Section
        title="Sobre o Sono"
        questions={[
          {
            label: "1 - Quantas horas você dorme em média por noite?",
            answer: Atendimento.q4_sono1,
          },
          {
            label: "2 - Quanto tempo demora para dormir? (Minutos)",
            answer: Atendimento.q4_sono2,
          },
          { label: "3 - Você tem insônia?", answer: Atendimento.q4_sono3 },
          { label: "4 - Período de insônia?", answer: Atendimento.q4_sono4 },
          {
            label: "5 - Após o sono sente-se disposto?",
            answer: Atendimento.q4_sono5,
          },
          { label: "6 - Seu sono é agitado?", answer: Atendimento.q4_sono6 },
          {
            label:
              "7 - Ocorre movimentos involuntários abruptos durante a noite?",
            answer: Atendimento.q4_sono7,
          },
          { label: "8 - Você fala dormindo?", answer: Atendimento.q4_sono8 },
          {
            label: "9 - Você anda durante o sono?",
            answer: Atendimento.q4_sono9,
          },
          {
            label: "10 - Você range os dentes durante a noite?",
            answer: Atendimento.q4_sono10,
          },
          {
            label:
              "11 - Você perde a força muscular das pernas durante a noite?",
            answer: Atendimento.q4_sono11,
          },
          {
            label: "12 - Você tem desmaios durante a noite?",
            answer: Atendimento.q4_sono12,
          },
        ]}
      />

      {/* Queixas Associadas */}
      <Section
        title="Queixas Associadas"
        questions={[
          {
            label: "1 - Você acorda com dor de cabeça?",
            answer: Atendimento.q5_queixa1,
          },
          {
            label: "2 - Você acorda para urinar?",
            answer: Atendimento.q5_queixa2,
          },
          {
            label: "3 - Você tem problemas de ordem sexual?",
            answer: Atendimento.q5_queixa3,
          },
          { label: "4 - Você tem depressão?", answer: Atendimento.q5_queixa4 },
          {
            label: "5 - Você apresentou ganho de peso nos últimos anos?",
            answer: Atendimento.q5_queixa5,
          },
          { label: "5.1 - Quanto?", answer: Atendimento.q5_queixa5_1 },
          {
            label: "6 - Qual o seu peso aproximado aos 20 anos?",
            answer: Atendimento.q5_queixa6,
          },
          {
            label: "7 - Já fez tratamento para emagrecer?",
            answer: Atendimento.q5_queixa7,
          },
          {
            label: "8 - Faz uso de medicamentos atualmente?",
            answer: Atendimento.q5_queixa8,
          },
          { label: "8.1 - Quais?", answer: Atendimento.q5_queixa8_1 },
          {
            label: "9 - Cirurgias anteriores? Quais?",
            answer: Atendimento.q5_queixa9,
          },
        ]}
      />

      {/* Escala de Sonolência Diurna */}
      <Section
        title="Escala de Sonolência Diurna de Epworth"
        questions={[
          { label: "1 - Sentado e lendo", answer: Atendimento.q6_escala1 },
          { label: "2 - Vendo televisão", answer: Atendimento.q6_escala2 },
          {
            label: "3 - Sentado em local público",
            answer: Atendimento.q6_escala3,
          },
          {
            label: "4 - Como passageiro de carro, ônibus ou trem",
            answer: Atendimento.q6_escala4,
          },
          {
            label: "5 - Deitado para descansar à tarde",
            answer: Atendimento.q6_escala5,
          },
          {
            label: "6 - Sentado conversando com alguém",
            answer: Atendimento.q6_escala6,
          },
          {
            label: "7 - Sentado calmamente após o almoço",
            answer: Atendimento.q6_escala7,
          },
          {
            label:
              "8 - Se você estiver de carro, e trânsito intenso com o carro parado",
            answer: Atendimento.q6_escala8,
          },
        ]}
      />

      {/* Pré Exame */}
      <Section
        title="Pré Exame"
        questions={[
          {
            label: "1 - Índice de ansiedade",
            answer: Atendimento.q7_preexame1,
          },
          {
            label: "2 - Dormiu bem a noite passada?",
            answer: Atendimento.q7_preexame2,
          },
          {
            label: "3 - Índice de nervosismo",
            answer: Atendimento.q7_preexame3,
          },
        ]}
      />
    </VStack>
  );
}
