import React, { useState } from "react";

import { Box, Button, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputAnamnese from "../../components/AnamneseInput";
import OptionsFrequencia from "../../components/AnamneseOpcoes";
import AnamneseOpcoesChance from "../../components/AnamneseOpcoesChance";
import AnamneseOpcoesEscala from "../../components/AnamneseOpcoesEscala";
import { AnamneseOpcoesIdentificacaoExame } from "../../components/AnamneseOpcoesIdentificacaoExame";
import AnamneseSIMeNAO from "../../components/AnamneseSN";
import PeriodoInsonia from "../../components/AnamnnesePeriodoInsonia";
import Loader from "../../components/Loader";
import api from "../../services/api";

export default function Anamnese() {
  const { id_cliente, id, token } = useParams();
  const [loading, setloading] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [vl_peso, setvl_peso] = useState("");
  const [q1_ronco1, setq1_ronco1] = useState("");
  const [q1_ronco2, setq1_ronco2] = useState("");
  const [q1_ronco3, setq1_ronco3] = useState("");
  const [q1_ronco4, setq1_ronco4] = useState("");
  const [q1_ronco5, setq1_ronco5] = useState("");
  const [vl_altura, setvl_altura] = useState("");
  const [q2_apneia1, setq2_apneia1] = useState("");
  const [q2_apneia2, setq2_apneia2] = useState("");
  const [q2_apneia3, setq2_apneia3] = useState("");

  const [q3_sonolencia1, setq3_sonolencia1] = useState("");
  const [q3_sonolencia2, setq3_sonolencia2] = useState("");
  const [q3_sonolencia3, setq3_sonolencia3] = useState("");
  const [q3_sonolencia4, setq3_sonolencia4] = useState("");
  const [q3_sonolencia5, setq3_sonolencia5] = useState("");

  const [q4_sono1, setq4_sono1] = useState("");
  const [q4_sono2, setq4_sono2] = useState("");
  const [q4_sono3, setq4_sono3] = useState("");
  const [q4_sono4, setq4_sono4] = useState("");
  const [q4_sono5, setq4_sono5] = useState("");
  const [q4_sono6, setq4_sono6] = useState("");
  const [q4_sono7, setq4_sono7] = useState("");
  const [q4_sono8, setq4_sono8] = useState("");
  const [q4_sono9, setq4_sono9] = useState("");
  const [q4_sono10, setq4_sono10] = useState("");
  const [q4_sono11, setq4_sono11] = useState("");
  const [q4_sono12, setq4_sono12] = useState("");

  const [q5_queixa1, setq5_queixa1] = useState("");
  const [q5_queixa2, setq5_queixa2] = useState("");
  const [q5_queixa3, setq5_queixa3] = useState("");
  const [q5_queixa4, setq5_queixa4] = useState("");
  const [q5_queixa5, setq5_queixa5] = useState("");
  const [q5_queixa6, setq5_queixa6] = useState("");
  const [q5_queixa7, setq5_queixa7] = useState("");
  const [q5_queixa8, setq5_queixa8] = useState("");
  const [q5_queixa9, setq5_queixa9] = useState("");
  const [q5_queixa8_1, setq5_queixa8_1] = useState("");
  const [q5_queixa5_1, setq5_queixa5_1] = useState("");

  const [q6_escala1, setq6_escala1] = useState("");
  const [q6_escala2, setq6_escala2] = useState("");
  const [q6_escala3, setq6_escala3] = useState("");
  const [q6_escala4, setq6_escala4] = useState("");
  const [q6_escala5, setq6_escala5] = useState("");
  const [q6_escala6, setq6_escala6] = useState("");
  const [q6_escala7, setq6_escala7] = useState("");
  const [q6_escala8, setq6_escala8] = useState("");

  const [q7_preexame1, setq7_preexame1] = useState("");
  const [q7_preexame2, setq7_preexame2] = useState("");
  const [q7_preexame3, setq7_preexame3] = useState("");
  const [q7_preexame4, setq7_preexame4] = useState("");

  const [sn_refazendo, setsn_refazendo] = useState("");
  const [tp_identificacao, settp_identificacao] = useState("");

  const handleSalvar = () => {
    setloading(true);
    api
      .post(
        "anamnese/salvar",
        {
          id,
          id_cliente,
          vl_altura,
          vl_peso,
          sn_refazendo,
          tp_identificacao,
          q1_ronco1,
          q1_ronco2,
          q1_ronco3,
          q1_ronco4,
          q1_ronco5,
          q2_apneia1,
          q2_apneia2,
          q2_apneia3,
          q3_sonolencia1,
          q3_sonolencia2,
          q3_sonolencia3,
          q3_sonolencia4,
          q3_sonolencia5,
          q4_sono1,
          q4_sono2,
          q4_sono3,
          q4_sono4,
          q4_sono5,
          q4_sono6,
          q4_sono7,
          q4_sono8,
          q4_sono9,
          q4_sono10,
          q4_sono11,
          q4_sono12,
          q5_queixa1,
          q5_queixa2,
          q5_queixa3,
          q5_queixa4,
          q5_queixa5,
          q5_queixa5_1,
          q5_queixa6,
          q5_queixa7,
          q5_queixa8,
          q5_queixa8_1,
          q5_queixa9,
          q6_escala1,
          q6_escala2,
          q6_escala3,
          q6_escala4,
          q6_escala5,
          q6_escala6,
          q6_escala7,
          q6_escala8,
          q7_preexame1,
          q7_preexame2,
          q7_preexame3,
          q7_preexame4,
          ds_leito: "",
        },
        config
      )
      .then(() => {
        window.location.href = "/send";
        setloading(false);
      })
      .catch(() => {
        setloading(false);
        toast.error("Erro! Verifique se todos os campos foram preenchidos", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <Box p={4}>
      <VStack alignItems={"left"}>
        <Loader display={loading} />

        <AnamneseOpcoesIdentificacaoExame
          id_cliente={id_cliente}
          handleSetVal={settp_identificacao}
        />

        <InputAnamnese
          title="Qual o seu peso?"
          orientacoes="Em KG"
          handleSetVal={setvl_peso}
        />

        <InputAnamnese
          title="Qual a sua altura?"
          orientacoes="Em CM"
          handleSetVal={setvl_altura}
        />

        <AnamneseSIMeNAO
          title="Você está refazendo o exame?"
          handleSetVal={setsn_refazendo}
        />

        <OptionsFrequencia
          title="1 - Você Ronca?"
          handleSetVal={setq1_ronco1}
        />
        <OptionsFrequencia
          title="2 - Seu ronco é alto?"
          handleSetVal={setq1_ronco2}
        />
        <OptionsFrequencia
          handleSetVal={setq1_ronco3}
          title="3 - A posição de dormir influência no ronco?"
        />
        <OptionsFrequencia
          handleSetVal={setq1_ronco4}
          title="4 - Você tem obstrução nasal?"
        />
        <OptionsFrequencia
          handleSetVal={setq1_ronco5}
          title="5 - Você tem coriza?"
        />

        <h2>Sobre apnéia</h2>
        <OptionsFrequencia
          handleSetVal={setq2_apneia1}
          title="1 - Alguém já lhe disse que você para de respirar durante a noite?"
        />
        <OptionsFrequencia
          nome_elemento="q2_apneia2"
          handleSetVal={setq2_apneia2}
          title="2 - Você já acordou engasgado?"
        />
        <OptionsFrequencia
          nome_elemento="q2_apneia3"
          handleSetVal={setq2_apneia3}
          title="3 - Você já acordou com falta de ar?"
        />

        <h2>Sobre Sonolência</h2>
        <OptionsFrequencia
          handleSetVal={setq3_sonolencia1}
          nome_elemento="q3_sonolencia1"
          title="1 - Você já dormiu no cinema?"
        />
        <OptionsFrequencia
          nome_elemento="q3_sonolencia2"
          handleSetVal={setq3_sonolencia2}
          title="2 - Você cochila lendo jornal?"
        />
        <OptionsFrequencia
          nome_elemento="q3_sonolencia3"
          handleSetVal={setq3_sonolencia3}
          title="3 - Você fica extremamente sonolendo no trânsito?"
        />
        <OptionsFrequencia
          nome_elemento="q3_sonolencia4"
          handleSetVal={setq3_sonolencia4}
          title="4 - Você já dormiu dirigindo?"
        />
        <OptionsFrequencia
          nome_elemento="q3_sonolencia5"
          handleSetVal={setq3_sonolencia5}
          title="5 - Você já dormiu em reunião importante?"
        />

        <h2>Sobre o Sono</h2>
        <InputAnamnese
          nome_elemento="q4_sono1"
          handleSetVal={setq4_sono1}
          title="1 - Quantas horas você dorme em média por noite?"
          orientacoes=""
        />
        <InputAnamnese
          nome_elemento="q4_sono2"
          handleSetVal={setq4_sono2}
          title="2 - Quanto tempo demora para dormir? (Minutos)"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono3"
          handleSetVal={setq4_sono3}
          title="3 - Você tem insônia?"
        />
        <PeriodoInsonia
          nome_elemento="q4_sono4"
          handleSetVal={setq4_sono4}
          title="4 - Período de insônia?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono5"
          handleSetVal={setq4_sono5}
          title="5 - Após o sono sente-se disposto?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono6"
          handleSetVal={setq4_sono6}
          title="6 - Seu sono é agitado?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono7"
          handleSetVal={setq4_sono7}
          title="7 - Ocorre movimentos involuntários abruptos durante a noite?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono8"
          handleSetVal={setq4_sono8}
          title="8 - Você fala dormindo?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono9"
          handleSetVal={setq4_sono9}
          title="9 - Você anda durante o sono?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono10"
          handleSetVal={setq4_sono10}
          title="10 - Você range os dentes durante a noite?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono11"
          handleSetVal={setq4_sono11}
          title="11 - Você perde a força muscular das pernas durante a noite?"
        />
        <OptionsFrequencia
          nome_elemento="q4_sono12"
          handleSetVal={setq4_sono12}
          title="12 - Você tem desmaios durante a noite?"
        />

        <h2>Queixas Associadas</h2>
        <OptionsFrequencia
          nome_elemento="q5_queixa1"
          handleSetVal={setq5_queixa1}
          title="1 - Você acorda com dor de cabeça?"
        />
        <OptionsFrequencia
          nome_elemento="q5_queixa2"
          handleSetVal={setq5_queixa2}
          title="2 - Você acorda para urinar?"
        />
        <OptionsFrequencia
          nome_elemento="q5_queixa3"
          handleSetVal={setq5_queixa3}
          title="3 - Você tem problemas de ordem sexual?"
        />
        <AnamneseSIMeNAO
          nome_elemento="q5_queixa4"
          handleSetVal={setq5_queixa4}
          title="4 - Você tem depressão?"
        />
        <AnamneseSIMeNAO
          nome_elemento="q5_queixa5"
          handleSetVal={setq5_queixa5}
          title="5 - Você apresentou ganho de peso nos últimos anos?"
        />
        <InputAnamnese
          handleSetVal={setq5_queixa5_1}
          nome_elemento="q5_queixa5_1"
          title="5.1 - Quanto?"
        />
        <InputAnamnese
          nome_elemento="q5_queixa6"
          handleSetVal={setq5_queixa6}
          title="6 - Qual o seu peso aproximado aos 20 anos?"
        />
        <AnamneseSIMeNAO
          nome_elemento="q5_queixa7"
          handleSetVal={setq5_queixa7}
          title="7 - Ja fez tratamento para emagrecer?"
        />
        <AnamneseSIMeNAO
          nome_elemento="q5_queixa8"
          handleSetVal={setq5_queixa8}
          title="8 - Faz uso de medicamentos atualmente?"
        />
        <InputAnamnese
          handleSetVal={setq5_queixa8_1}
          nome_elemento="q5_queixa8_1"
          title="8.1 - Quais?"
        />
        <InputAnamnese
          nome_elemento="q5_queixa9"
          handleSetVal={setq5_queixa9}
          title="9 - Cirurgias anteriores? Quais?"
        />

        <h2>Escala de sonolência diurna de epwoth</h2>
        <AnamneseOpcoesChance
          nome_elemento="q6_escala1"
          handleSetVal={setq6_escala1}
          title="1 - Sentado e lendo"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala2"
          handleSetVal={setq6_escala2}
          title="2 - Vendo televisão"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala3"
          handleSetVal={setq6_escala3}
          title="3 - Sentado em local público"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala4"
          handleSetVal={setq6_escala4}
          title="4 - Como passageiro de carro, ônibus ou trem"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala5"
          handleSetVal={setq6_escala5}
          title="5 - Deitado para descansar à tarde"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala6"
          handleSetVal={setq6_escala6}
          title="6 - Sentado conversando com alguém"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala7"
          handleSetVal={setq6_escala7}
          title="7 - Sentado calmamente após o almoço"
        />
        <AnamneseOpcoesChance
          nome_elemento="q6_escala8"
          handleSetVal={setq6_escala8}
          title="8 - Se você estiver de carro, e trânsito intenso com o carro parado"
        />

        <h2>PRÉ-EXAME</h2>
        <AnamneseOpcoesEscala
          nome_elemento="q7_preexame1"
          handleSetVal={setq7_preexame1}
          title="1 - Indíce de ansiedade"
        />
        <AnamneseSIMeNAO
          nome_elemento="q7_preexame2"
          handleSetVal={setq7_preexame2}
          title="2 - Dormiu bem a noite passada?"
        />
        <AnamneseOpcoesEscala
          nome_elemento="q7_preexame3"
          handleSetVal={setq7_preexame3}
          title="3 - índice de nervosismo"
        />
        <InputAnamnese
          nome_elemento=""
          handleSetVal={setq7_preexame4}
          title="4 - Ingeriu café, chá-mate, refrigerante, chocolate, calmanete?"
        />

        <Button onClick={() => handleSalvar()} colorScheme="blue">
          Salvar
        </Button>
      </VStack>
    </Box>
  );
}
