import React, { Fragment, useEffect, useState } from "react";

import { Select } from "@chakra-ui/react";
import api from "../../services/api";

interface Action {
  handleSetVal: () => void;
  id_cliente: string;
}

export function AnamneseOpcoesIdentificacaoExame({
  handleSetVal,
  id_cliente,
}: Action) {
  const [identificacoes, setIdentificacoes] = useState([]);

  useEffect(() => {
    api
      .post("identificacao/find", {
        id_cliente,
      })
      .then((res) => {
        setIdentificacoes(res.data);
      });
  }, [id_cliente]);

  return (
    <Fragment>
      <h2>Identificação</h2>
      <Select
        onChange={(e) => handleSetVal(e.target.value)}
        bg="white"
        placeholder="Selecione uma opção"
        color={"gray.800"}
      >
        {identificacoes.map((indetificacao, index) => (
          <option key={index} value={indetificacao.id}>
            {indetificacao.tp_identificacao}
          </option>
        ))}
      </Select>
    </Fragment>
  );
}
